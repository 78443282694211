import React from 'react';

const Loading = () => {
    return (
        <div className="flex justify-center items-center w-full h-[500px]">
            <div className="loader"></div>
        </div>
    );
}

export default Loading;
