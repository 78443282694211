import React, { useState } from 'react';
import { getLoggedInUser, getMemberPressUser, loginWithUserNamePassword } from '../api/wp/handlers';

const Login = ({ setUser }) => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

		const [loading, setLoading] = useState(false)


		const setAuthTokenInLocalStorage = (token) => {
			const expiryDate = new Date();
			expiryDate.setDate(expiryDate.getDate() + 7); // Adds 7 days to the current date

			const authData = {
					token: token,
					expiry: expiryDate.toISOString() // Stores expiry date in ISO format
			};

			localStorage.setItem('user', JSON.stringify(authData)); // Serializing the object to a string
		};

		const handleLogin = (e) => {
			e.preventDefault()
			setLoading(true)
			loginWithUserNamePassword(username, password)
				.then(async (res) => {
					// handle successful login
					if(res.status === 200) {
						const { token, user_email, user_nicename, user_display_name } = res.data
						const id = (await getLoggedInUser(token)).data.id
						const memberships = ((await getMemberPressUser(id)).data.active_memberships).map(membership => membership.title)
						const user = {
							id,
							token,
							email: user_email,
							username: user_nicename,
							name: user_display_name,
							memberships,
						}
						const { username, email, name } = user
						setUser({token, id, username, email, name, memberships})
						setAuthTokenInLocalStorage(user.token)
						setLoading(false)
					}
				})
				.catch(error => {
					console.log(error);
					// if(error.response.data.code === '[jwt_auth] incorrect_password') {
					// 	alert('The password you entered is incorrect!')
					// 	setLoading(false)
					// } else if(error.response.data.code === '[jwt_auth] invalid_username') {
					// 	alert('The username you entered is incorrect!')
					// 	setLoading(false)
					// } else {
					// 	alert(error.message)
					// 	setLoading(false)
					// }
				})
		}

    return (
        <div className="container mx-auto">
            <p className="text-[32px] font-bold mb-6 text-white">Login to your account</p>
            <form onSubmit={handleLogin} className="p-10 bg-white shadow-lg w-full">
                <div className="mb-4">
                    <label htmlFor="username" className="block text-black font-bold mb-2">Username</label>
                    <input
                        type="text"
                        id="username"
                        className="w-full p-2 border border-gray-300 rounded shadow-sm"
                        placeholder="Enter your username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
                <div className="mb-6">
                    <label htmlFor="password" className="block text-black font-bold mb-2">Password</label>
                    <input
                        type="password"
                        id="password"
                        className="w-full p-2 border border-gray-300 rounded shadow-sm"
                        placeholder="Enter your password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <button type="submit" className="w-full bg-themeColor hover:bg-themeColor-dark font-bold py-2 px-4 text-black">
                    {loading ? "Logging in" : "Login"}
                </button>
            </form>
        </div>
    );
};

export default Login;
