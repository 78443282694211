import { useEffect, useState } from "react";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Transactions from "./components/Transactions";
import Login from "./components/Login";
import { getLoggedInUser, getMemberPressUser } from "./api/wp/handlers";
import Loading from "./components/Loading";

const App = () => {

  const [user, setUser] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    const userObject = localStorage.getItem("user")
    if(userObject) {
      const loggedInUser = JSON.parse(userObject)
      
      if(loggedInUser) {
        getLoggedInUser(loggedInUser.token)
        .then((res) => {
          if(res.data?.message === 'Expired token' || res.data?.message === 'You are not currently logged in.' || res.status === 404 || res.status === 401) {
            alert('Session expired, please login again.')
          } else {
            getMemberPressUser(res.data.id)
            .then((res) => {
              setUser(res.data)
              setLoading(false)
            })
          }
        })
      } else {
        setLoading(false)
      }
    } else {
      setLoading(false)
    }
  }, [])

  const handleLogout = () => {
    localStorage.setItem("user", "")
    setUser(false)
  }

  return (
    <div className="w-full h-fit bg-black pl-[20px] md:p-[120px] lg:p-[120px] pr-[20px] pt-[20px] md:pt-[20px] lg:pt-[20px] pb-[20px]">
      <Header user={user} handleLogout={handleLogout}/>
        {
          !loading
          ?
          user
          ?
          <Transactions user={user}/>
          :
          <Login setUser={setUser}/>
          :
          <Loading />
        }
      <Footer />
    </div>
  );
}

export default App;
