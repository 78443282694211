import Logo from '../assets/logos/logo_black.png'

const Header = ({ user, handleLogout }) => {
	return (
		<div className="flex flex-col items-center md:items-start lg:items-start w-full h-max bg-black pb-4 mt-4">
			<img src={Logo} alt='logo' className="pt-2 pb-2 pl-4 pr-4 h-[110px]" />
			<div className='mt-4 bg-themeColor px-6 w-full flex uppercase font-bold'>
				{
					user
					&&
					<p className='nav-item' onClick={handleLogout}>Logout</p>
				}
			</div>
		</div>
	)
}

export default Header