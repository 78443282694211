import axios from "axios";

const FIREBASE_FUNCTIONS_BASE_URL = "https://us-central1-how-to-beat-the-bookie.cloudfunctions.net/referralAPI";

export const loginWithUserNamePassword = async(username, password) => {
    const loginRequest = {
        method: 'POST',
        url: `${FIREBASE_FUNCTIONS_BASE_URL}/login`,
        data: {
            username: username,
            password: password
        }
    };
    return await axios(loginRequest);
};

export const getLoggedInUser = async(token) => {
    const userRequest = {
        method: 'GET',
        url: `${FIREBASE_FUNCTIONS_BASE_URL}/user`,
        headers: {
            Authorization: `Bearer ${token}`
        },
    };
    return await axios(userRequest);
};

export const getMemberPressUser = async(id) => {
    const userRequest = {
        method: 'GET',
        url: `${FIREBASE_FUNCTIONS_BASE_URL}/memberpress-user/${id}`,
        headers: {
            "MEMBERPRESS-API-KEY": process.env.REACT_APP_MP_API_KEY
        },
    };
    return await axios(userRequest);
};
