import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Loading from './Loading';
import moment from 'moment';

const Transactions = ({ user }) => {
    const [transactions, setTransactions] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(moment().month());

    useEffect(() => {
        const fetchData = async () => {
            try {
                const coupon = user.username === 'admin' ? 'skinny' : user.username;
                const response = await axios.get(`https://us-central1-how-to-beat-the-bookie.cloudfunctions.net/referralAPI/transactions/${coupon}`);
                setTransactions(response.data);
            } catch (error) {
                if (error.response && error.response.data === "Coupon not found") {
                    setTransactions([]);
                    console.log(error.message);
                }
            }
        };

        fetchData();
    }, [user.username]);

    const handleNextMonth = () => {
        setSelectedMonth(selectedMonth+1);
    };

    const handlePrevMonth = () => {
        setSelectedMonth(selectedMonth-1);
    };

    return (
        transactions !== null
        ?
        <div className="container flex flex-col mx-auto">
            <h1 className="text-[24px] md:text-[32px] lg:text-[32px] font-bold mb-5 text-white">Affiliate Portal: <span>{user.username}</span></h1>
            <div className='flex justify-between w-full mb-2 font-bold '>
                <button className='w-fit px-2 py-2 bg-white rounded select-none cursor-pointer' onClick={handlePrevMonth}>Previous Month</button>
                <button className='w-fit px-2 py-2 bg-white rounded select-none cursor-pointer' onClick={handleNextMonth}>Next Month</button>
            </div>
            <div className="block lg:hidden">
                {transactions.length>0
                    ?
                    <>
                        {transactions.filter((tx) => moment(tx.created_at).month() === selectedMonth).map((transaction, index) => (
                            <div key={index} className="bg-white p-4 shadow mb-5">
                                <div><strong>Created At:</strong> {transaction.created_at}</div>
                                <div><strong>User ID:</strong> {transaction.member.username || transaction.member.id}</div>
                                <div><strong>Transaction Number:</strong> {transaction.trans_num}</div>
                                <div><strong>Status:</strong> <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${transaction.status === 'complete' ? 'bg-green-100 text-green-800' : transaction.status === 'pending' ? 'bg-yellow-100 text-yellow-800' : 'bg-red-100 text-red-800'}`}>{transaction.status}</span></div>
                                <div><strong>Commissions:</strong> $96.52</div>
                            </div>
                        ))}
                    </>
                    :
                    <div className='w-full bg-black'>
                        <p className="text-[24px] md:text-[32px] lg:text-[32px] text-white">No transactions found</p>
                    </div>
                }
            </div>
            <div className="hidden md:block lg:block">
                <table className="min-w-full table-auto shadow-md bg-white">
                    <thead className="bg-themeColor">
                        <tr>
                            <th className="col">Created At</th>
                            <th className="col">User ID</th>
                            <th className="col">Transaction Number</th>
                            <th className="col">Status</th>
                            <th className="col">Commissions</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {transactions.length>0
                            ?
                            <>
                                {transactions.filter((tx) => moment(tx.created_at).month() === selectedMonth).map((transaction, index) => (
                                    <tr key={index} className='even:bg-gray-50'>
                                        <td className="col-item">{transaction.created_at}</td>
                                        <td className="col-item">{transaction.member.username || transaction.member.id}</td>
                                        <td className="col-item">{transaction.trans_num}</td>
                                        <td className="col-item">
                                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${transaction.status === 'complete' ? 'bg-green-100 text-green-800' : transaction.status === 'pending' ? 'bg-yellow-100 text-yellow-800' : 'bg-red-100 text-red-800'}`}>
                                                {transaction.status}
                                            </span>
                                        </td>
                                        <td className="col-item">$96.52</td>
                                    </tr>
                                ))}
                                <td className="col-item font-extrabold">Total</td>
                                <td className="col-item"></td>
                                <td className="col-item"></td>
                                <td className="col-item"></td>
                                <td className="col-item ml-auto font-extrabold">
                                    ${(96.52*transactions.filter((tx) => moment(tx.created_at).month() === selectedMonth).length).toFixed(2)}
                                </td>
                            </>
                            :
                            <>
                                <p className="px-8 py-10 text-[18px] md:text-[28px] lg:text-28px] text-black">No transactions found</p>
                            </>
                        }
                    </tbody>
                </table>
            </div>
        </div>
        :
        <Loading />
    );
};

export default Transactions;
